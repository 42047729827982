import React from "react"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

const Thesis: React.FC<GatsbyProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="Thesis" />
    <h1>Thesis</h1>
    <p>
      My thesis was an exploration into the development of a board game which
      incorporated augmented reality. The outcome of this exploration was a
      pre­-prototype set of rules that was built on the principles of
      interaction and iterative design, as well as a proof of concept technical
      demonstration that added a visual understanding of the concepts being
      explored. Combined together, the two productions are the end product of
      the research into interaction design applied in the context of a digitally
      enhanced board game, that lays a foundation on the topic that is ready to
      be built upon.
    </p>
    <ResponsiveIFrame src="https://player.vimeo.com/video/133384034" />
  </StandardLayout>
)

export default Thesis
